#contact {
	background-color: #FFFFFF;
	padding: 30px;
	.container {
		max-width: 1200px;
		margin: 0 auto;
		
	}
}

.contactInfo {
	margin: 20px 0;
	display: flex;
	align-items: center;
	justify-content: center;
	span {
		font-size: 18px;
		line-height: 1.75;
		&.phone {
			padding-top: 1px;
			display: inline-block;
		}
		a {
			color: inherit;
			text-decoration: none;
		}
	}
}

.icon {
	width: 20px;
	margin: 0 5px;
	fill: var(--coral);
}
