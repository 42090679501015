nav {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: var(--hot-pink);
	width: 100%;
	z-index: 1;
	font-weight: 200;
}

.nameLink {
	color: white;
	text-decoration: none;
	font-size: 26px;
	margin-left: 25px;
	&:hover {
		color: var(--coral);
		cursor: default;
	}
}

ul {
	display: flex;
	align-items: center;
}

li {
	list-style-type: none;
	margin: 20px 30px;
	a {
		color: white;
		text-decoration: none;
		font-size: 20px;
		margin-right: 5px;
		padding: 5px 5px;
		&:hover {
			border-bottom: 3px solid var(--coral);
		}
	}
}

.github {
	margin-left: -30px;
	&:hover {
		border: 0px;
	}
}

.githubIcon {
	fill: white;
	width: 24px;
	&:hover {
		fill: var(--coral);
	}
}

.navScrolled {
	position: fixed;
	top: 0;
	opacity: .8;
}

@media only screen and (max-width: 900px) {
	nav {
		justify-content: space-around;
	}
	
	.nameLink {
		display: none;
	}

	li {
		margin: 10px;
	}

	.github {
		margin-left: 0;
	}
}

@media only screen and (max-width: 600px) {
	nav {
		justify-content: center;
	}
	
	li {
		margin: 5px;
		a {
			font-size: 18px;
		}
	}
}
