#about {
	.container {
		padding: 20px;
		max-width: 1200px;
		margin: 0 auto;
		display: grid;
		grid-template-columns: 3fr 2fr;
		grid-gap: 30px;
		align-items: top;
		justify-items: center;
	}
	img {
		width: 350px;
		margin: 30px;
		border-radius: 15px;
		position: center;
	}
}

.bio {
	grid-column: 1 / 2;
	width: 100%;
	padding: 40px;
	line-height: 1.75;
	h1 {
		margin-bottom: 10px;
		color: var(--coral);
		font-size: 36px;
	}
	p {
		text-align: left;
		padding-left: 10px;
		border-left: 3px solid var(--coral);
		span {
			color: var(--coral);
		}
	}
}

@media only screen and (max-width: 1000px) {
	#about {
		.container {
			grid-template-columns: 5fr 4fr;
			grid-gap: 30px;
			padding: 30px;
		}
		img {
			margin: 0 auto;
		}
	}
	
	.bio {
		margin: 0 auto;
		h1 {
			font-size: 30px;
		}
	}
}

@media only screen and (max-width: 800px) {
	#about {
		.container {
			display: flex;
			flex-direction: column-reverse;
			padding: 15px;
		}
		img {
			width: 100%;
		}
	}

	.bio {
		width: 100%;
		padding: 20px;
		line-height: 1.5;
		h1 {
			font-size: 24px;
		}
	}
}
