@import url('https://fonts.googleapis.com/css2?family=Azeret+Mono:wght@200;400&display=swap');

:root {
	--hot-pink: #FF4AA5;
	--coral: #FFB59A;
	--lighter-pink: #FFCDE6;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
	text-align: center;
	font-family: 'Azeret Mono', sans-serif;
	color: var(--hot-pink);
	font-size: 14px;
}

//page styles
html {
	scroll-behavior: smooth;
	scroll-padding-top: 69px;
}

img {
	width: 100%;
	height: auto;
}

h1 {
	font-size: 24px;
}

.scrolled {
	margin-top: 69px;
}

//small width
@media only screen and (max-width: 600px) {
	.scrolled {
		margin-top: 41px;
	}
}
