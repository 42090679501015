.projectContainer {
	display: flex;
	flex-wrap: wrap;
	margin: 35px auto;
	gap: 20px;
	padding: 30px;
	max-width: 1000px;
}

.projectDiv {
	width: 300px;
	background-color: white;
	transform: translate(0);
	transition: transform 100ms linear;
	border: 3px dotted var(--lighter-pink);
	border-radius: 15px;
	img {
		width: 100%;
		border-radius: 15px 15px 0 0;
	}
	a {
		text-decoration: none;
	}
	&:hover {
		transform: translate(5px, -5px);
		filter: drop-shadow(-15px 15px 15px var(--lighter-pink));
	}
}

.projectTitle {
	padding: 10px 0;
}

@media only screen and (max-width: 875px) {
	.projectDiv {
		width: 30%;
	}
}

@media only screen and (max-width: 600px) {
	.projectContainer {
		flex-direction: column;
		align-items: center;
		margin: 20px auto;
	}

	.projectDiv {
		width: 50%;
		margin: 15px auto;
	}
}

@media only screen and (max-width: 450px) {
	.projectDiv {
		width: 70%;
	}
}
