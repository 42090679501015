#skills {
	background-color: #FFFFFF;
	padding: 30px;
	h1 {
		margin-bottom: 25px;
	}
	.container {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		max-width: 800px;
		margin: 0 auto;
	}
}

.iconDivs {
	display: flex;
	justify-content: center;
	flex-direction: column;
	flex-basis: 20%;
	margin: 20px 0;
	transform: translateY(0);
	transition: transform 150ms ease-out;
	&:hover {
		transform: translateY(-10px);
	}
}

.iconBg {
	background-color: var(--lighter-pink);
	width:100px;
	height: 100px;
	margin: 0 auto;
	border-radius: 50%;
	display: flex;
	align-items: center;
	border: 2px solid var(--hot-pink);
}

.icons {
	width: 60%;
	margin: 0 auto;
	fill: var(--hot-pink);
}

@media only screen and (max-width: 800px) {
	.iconDivs {
		flex-basis: 30%;
	}
}

@media only screen and (max-width: 450px) {
	.iconDivs {
		flex-basis: 50%;
	}
}
